import SiteWrapper from "../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../../components/mdrender";
import * as md from "../../components/mdrender.module.sass";
import TitleBlock from "../../components/title";

import "./automation-actions@gen.scss";

const GeneratedAutomationAutomationActions = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          PagerDuty Automation Actions｜正規販売代理店（株）Digital Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="PagerDuty Automation Actionsでは、レスポンダーを修正自動化プロセスに接続し、インシデントを迅速に診断して修正できます。今すぐお試しください。"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="PagerDuty Automation Actionsでは、レスポンダーを修正自動化プロセスに接続し、インシデントを迅速に診断して修正できます。今すぐお試しください。"
        />

        <meta
          property="og:title"
          content="PagerDuty Automation Actions｜正規販売代理店（株）Digital Stacks"
        />

        <meta
          property="og:description"
          content="PagerDuty Automation Actionsでは、レスポンダーを修正自動化プロセスに接続し、インシデントを迅速に診断して修正できます。今すぐお試しください。"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/automation/automation-actions/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/automation/automation-actions/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Automation"
        textGreen="PagerDuty Automation Actions"
        overview=""
      />

      <div className="itoperationsWrapper">
        <div className="itoperations-container">
          <div className="mt-0 center-1">
            <h3 className="center-title md:mb-[0px] mb-[30px]">
              レスポンダーをPagerDuty内の修正自動化に接続し、インシデントを迅速に診断して修復できます。
            </h3>
          </div>
          <div className="three-col-blog-card-image-grey bg-transparent py-0 my-0 md:mt-[50px]">
            <div className="card">
              <div className="icon-section mb-[30px] min-h-[100px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="{84}"
                  height="{80}"
                  className="w-[80px] aspect-square"
                  viewBox="0 0 84 80"
                  fill="none"
                >
                  <path
                    d="M38.6879 49.7899H24.5332V69.6064H38.6879V49.7899Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M58.499 38.4731H44.3442V69.6134H58.499V38.4731Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M18.8764 58.5661H4.72168V69.6064H18.8764V58.5661Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M83.7404 20.5365L72.1057 0.500307C71.9258 0.190523 71.5956 0 71.2376 0C70.8811 0 70.5507 0.190687 70.371 0.500307L58.7362 20.5365C58.5563 20.8463 58.5563 21.2291 58.7347 21.5401C58.9131 21.8512 59.2448 22.0433 59.6042 22.0433H64.1601V69.6135H78.3161V22.0433H82.8721C83.2316 22.0433 83.5633 21.8512 83.7417 21.5401C83.9214 21.2291 83.9201 20.8463 83.7404 20.5365Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M83.0397 73.3939H0V80H83.0397V73.3939Z"
                    fill="#53B54B"
                  />
                </svg>
              </div>
              <h4 className="title">平均修復時間を最大25％削減</h4>
              <p className="para">
                自動診断により、レスポンダーが問題を迅速にトリアージできるようになり、解決までの時間が短縮されます。
              </p>
            </div>
            <div className="card">
              <div className="icon-section mb-[30px] min-h-[100px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-[80px] aspect-square"
                  width="{80}"
                  height="{80}"
                  viewBox="0 0 80 80"
                  fill="none"
                >
                  <path
                    d="M80 0H0V6.25H2.5V52.5H77.5V6.25H80V0ZM72.5 47.5H7.5V6.25H72.5V47.5Z"
                    fill="#53B54B"
                  />
                  <path d="M42.5 55H37.5V80H42.5V55Z" fill="#53B54B" />
                  <path
                    d="M17.6987 80H22.6987L33.1528 55H28.1528L17.6987 80Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M46.8467 55L57.3007 80H62.3007L51.8467 55H46.8467Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M23.4058 33.5755C25.4322 33.5755 27.0728 31.9336 27.0728 29.9084C27.0728 29.6264 27.0338 29.3542 26.9739 29.0894L35.3686 23.6559C35.9839 24.1224 36.7406 24.4092 37.5708 24.4092C37.6746 24.4092 37.7722 24.3872 37.8736 24.3788L39.9939 28.9722C39.3225 29.6363 38.9063 30.5566 38.9063 31.5759C38.9063 33.6011 40.5469 35.243 42.5708 35.243C44.5972 35.243 46.2378 33.6011 46.2378 31.5759C46.2378 31.3269 46.2122 31.0839 46.1646 30.8495L57.378 24.0124C58.0055 24.5141 58.7903 24.8266 59.6558 24.8266C61.6822 24.8266 63.3228 23.1847 63.3228 21.1595C63.3228 19.1342 61.6822 17.4936 59.6558 17.4936C57.6306 17.4936 55.99 19.1342 55.99 21.1595C55.99 21.4086 56.0156 21.6514 56.0631 21.8858L44.8486 28.723C44.2211 28.2227 43.4363 27.9102 42.5708 27.9102C42.4683 27.9102 42.3717 27.932 42.2705 27.9406L40.1502 23.3459C40.8216 22.6819 41.2378 21.7614 41.2378 20.7422C41.2378 18.717 39.5972 17.0764 37.5708 17.0764C35.5469 17.0766 33.9063 18.7172 33.9063 20.7422C33.9063 21.0253 33.9453 21.2975 34.0052 21.5613L25.6092 26.9947C24.9952 26.5297 24.2371 26.2428 23.4058 26.2428C21.3794 26.2428 19.7388 27.8834 19.7388 29.9086C19.7388 31.9338 21.3794 33.5755 23.4058 33.5755Z"
                    fill="#53B54B"
                  />
                </svg>
              </div>
              <h4 className="title">エキスパートの時間を確保</h4>
              <p className="para">
                エスカレーションを減らしてインシデントを迅速に解決し、開発者を問題対応ではなく開発業務に集中させられます。
              </p>
            </div>
            <div className="card">
              <div className="icon-section mb-[30px] min-h-[100px]">
                <svg
                  className="w-[80px] aspect-square"
                  xmlns="http://www.w3.org/2000/svg"
                  width="{80}"
                  height="{78}"
                  viewBox="0 0 80 78"
                  fill="none"
                >
                  <path
                    d="M78.5337 50.058C76.5668 47.9133 72.901 48.3691 70.4487 50.1836C68.208 52.0489 56.8848 59.5258 56.8848 59.5258H38.2679L38.2087 59.5555C37.228 59.5216 36.4618 58.7005 36.4916 57.7195C36.5269 56.7403 37.3522 55.9714 38.3258 56.0052H51.9843C54.7371 56.0052 56.9708 53.7419 56.9708 50.9891C56.9708 48.2319 54.7372 45.9997 51.9843 45.9997C49.4897 45.9997 44.5058 45.9997 32.0383 45.9997C19.5693 45.9997 16.9774 51.2388 14.8101 54.8355V75.4406L38.1693 77.8492C46.9583 78.3303 51.173 77.8492 57.4352 72.5523C57.4352 72.5523 76.1338 58.8895 78.3843 57.0186C80.5246 55.0544 80.5019 52.2028 78.5337 50.058Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M10.8366 49.9747H0V76.3436H10.8366V49.9747Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M41.2394 40.0414C45.6619 40.0414 49.2469 36.4563 49.2469 32.0339C49.2469 27.6115 45.6619 24.0264 41.2394 24.0264C36.817 24.0264 33.2319 27.6115 33.2319 32.0339C33.2319 36.4563 36.817 40.0414 41.2394 40.0414Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M60.2609 27.0038C64.034 27.0038 67.0917 23.9459 67.0917 20.1744C67.0917 16.4027 64.034 13.345 60.2609 13.345C56.4908 13.345 53.4331 16.4027 53.4331 20.1744C53.4331 23.9459 56.4908 27.0038 60.2609 27.0038Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M43.4052 11.6858C46.6308 11.6858 49.2453 9.07108 49.2453 5.84702C49.2453 2.62155 46.6306 0.00686646 43.4052 0.00686646C40.1811 0.00686646 37.5664 2.62155 37.5664 5.84702C37.5664 9.07108 40.1811 11.6858 43.4052 11.6858Z"
                    fill="#53B54B"
                  />
                </svg>
              </div>
              <h4 className="title">自動化実行権限をレスポンダーに付与</h4>
              <p className="para">
                サービスの修正オートメーションを起動し、インシデント発生時にレスポンダーに安全に権限を委譲できます。
              </p>
            </div>
          </div>
          <div className="text-center mt-[100px] mb-[30px]">
            <h3 className="relative center-title max-w-max">
              製品機能
              <div className="absolute w-[30%] h-[1.5px] bottom-[-10px] mx-auto bg-[#4EB346]"></div>
            </h3>
          </div>
          <div className="two-col-media-contents gap-[50px] mt-[0px] md:mt-[10px]">
            <div className="text-part">
              <p className="title">本番環境のオートメーションに安全に接続</p>
              <p className="para">
                ファイアウォールやVPCの背後にあるオートメーションをPagerDutyから直接安全に呼び出すことができます。ワークフローや他のオートメーションに接続可能です。
              </p>
            </div>
            <div className="p-0 media-contents-part">
              <img
                id="fullImage"
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Automation/Automation_actions/2_5_1_1799cc6727.png?ik-sdk-version=javascript-1.4.3&updatedAt=1673301608300&tr=w-480,q-100,f-webp"
                alt="automation image"
              />
            </div>
          </div>
          <div className="two-col-media-contents gap-[50px] mt-[60px] md:mt-[60px]">
            <div className="text-part">
              <p className="title">診断と修復、よくある問題の解決を自動化</p>
              <p className="para">
                レスポンダーは自動化された手順を呼び出して一般的な診断手順を実行、フェイルオーバーなど一般的な措置の自動化で繰り返し発生する問題の解決を自動化します。
              </p>
            </div>
            <div className="p-0 media-contents-part">
              <img
                id="fullImage"
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Automation/Automation_actions/automation_pd_interface_run_action_run_job_1_1_ec9a2095c9.png?ik-sdk-version=javascript-1.4.3&updatedAt=1673301635625&tr=w-480,q-100,f-webp"
                alt="auto motion iamge 3"
              />
            </div>
          </div>
          <div className="two-col-media-contents gap-[50px] mt-[60px] md:mt-[60px]">
            <div className="text-part">
              <p className="title">自動化をエンドユーザーにデリゲート可能</p>
              <p className="para">
                全ての対応をエキスパートに無用にエスカレーションしてしまうことを避けて、オペレーターに自動化処理を安全に委ねることができます。
              </p>
            </div>
            <div className="p-0 media-contents-part">
              <img
                id="fullImage"
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Automation/Automation_actions/automation_jobs_page_1_1_8d7c1c1721.png?ik-sdk-version=javascript-1.4.3&updatedAt=1673301608189&tr=w-480,q-100,f-webp"
                alt="autoMotionImage"
              />
            </div>
          </div>
          <div className="two-col-media-contents gap-[50px] mt-[60px] md:mt-[60px]">
            <div className="text-part">
              <p className="title">自己回復型のオートメーション</p>
              <p className="para">
                レスポンダーが呼び出される前に、自己回復型の自動化をトリガーします。ジョブイベントルールによるイベントドリブンな自動化を実現します。
              </p>
            </div>
            <div className="p-0 media-contents-part">
              <img
                id="fullImage"
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Automation/Automation_actions/self_healing_automation_actions_1_1_14457bb923.png?ik-sdk-version=javascript-1.4.3&updatedAt=1673301608315&tr=w-480,q-100,f-webp"
                alt="autoMotionImage"
              />
            </div>
          </div>
          <div className="two-col-media-contents gap-[50px] mt-[60px] md:mt-[60px]">
            <div className="text-part">
              <p className="title">Slackでオートメーションを呼び出し可能</p>
              <p className="para">
                レスポンダーは、自動化された診断と修復アクションをSlackから直接デプロイ可能。端末からサービスへのアクセスが不要になり、インシデント解決を効率化できます。
              </p>
            </div>
            <div className="p-0 media-contents-part">
              <img
                id="fullImage"
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Automation/Automation_actions/automation_actions_collabops_slack_1_1_c1479ff2e2.png?ik-sdk-version=javascript-1.4.3&updatedAt=1673301608779&tr=w-480,q-100,f-webp"
                alt="autoMotionImage"
              />
            </div>
          </div>
          <div className="two-col-media-contents gap-[50px] mt-[60px] md:mt-[60px]">
            <div className="text-part">
              <p className="title">どこからでもオートメーションが可能</p>
              <p className="para">
                スマートデバイスのPagerDutyアプリから直接、自動診断と修復をトリガーすることが可能。どこにいても自動化アクションを実行することができます。
              </p>
            </div>
            <div className="p-0 media-contents-part">
              <img
                id="fullImage"
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Automation/Automation_actions/automation_actions_mobile_944_1_1_2e98ae9a2a.png?ik-sdk-version=javascript-1.4.3&updatedAt=1673301608503&tr=w-480,q-100,f-webp"
                alt="autoMotionImage"
              />
            </div>
          </div>
          <div className="two-col-media-contents gap-[50px] mt-[60px] md:mt-[60px]">
            <div className="text-part">
              <p className="title">
                イベントオーケストレーションからのデプロイ
              </p>
              <p className="para">
                ネストされたイベントルールと機械学習による的確な自動化トリガーで、一般的な診断の自動化やよくあるインシデントの自己回復を含め、呼び出し前にアクションを起こせます。
              </p>
            </div>
            <div className="p-0 media-contents-part">
              <img
                id="fullImage"
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Automation/Automation_actions/event_orchestration_v3_6_edit_action_rule_process_automation_1_2_7631c1908c.png?ik-sdk-version=javascript-1.4.3&updatedAt=1673301608636&tr=w-480,q-100,f-webp"
                alt="autoMotionImage"
              />
            </div>
          </div>
          <div className="two-col-media-contents gap-[50px] mt-[60px] md:mt-[60px]">
            <div className="text-part">
              <p className="title">Salesforceから直接インシデントを解決</p>
              <p className="para">
                自動化アクションをService
                CloudのPagerDutyアプリから直接実行することで、顧客に影響を与える問題を検証することができます。
              </p>
            </div>
            <div className="p-0 media-contents-part">
              <img
                id="fullImage"
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Automation/Automation_actions/servcloud_automatedactions_2a_1_2_5f695d7998.png?ik-sdk-version=javascript-1.4.3&updatedAt=1673302145340&tr=w-480,q-100,f-webp"
                alt="autoMotionImage"
              />
            </div>
          </div>
          <div className="center-2 mb-[30px]">
            <p className="center-para">
              自動化アクションでは、ファイアウォールまたはVPC内にデプロイされたアクションランナーを通じて本番インフラに接続し、自動化アクションのエンドポイントに戻る暗号化された接続を提供します。エンジニアは、自動化アクションのランナーを通じて公開される自動化された機能を管理し、公開することができます。この新しい自動化は、関連するサービスがインシデントに巻き込まれたときに、レスポンダーが利用できるようになります。
            </p>
          </div>
          <img
            className="mb-[80px] p-4 md:p-0"
            src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/automation_actions_architecture_optimized_c4c3ec511d.svg?updated_at=2022-10-11T03:34:59.804Z"
            alt="automation"
          />
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedAutomationAutomationActions;
